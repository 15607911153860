<!--
 * @Author: error: git config user.name && git config user.email & please set dead value or install git
 * @Date: 2022-05-25 14:30:37
 * @LastEditors: error: git config user.name && git config user.email & please set dead value or install git
 * @LastEditTime: 2022-06-15 17:34:38
 * @FilePath: \newwifisvn\src\views\download\index.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="download">
    <!-- :style="{'background-image':`url(${getImageUrl()})`}" -->
   <van-overlay :show="show">
   <img src="@/assets/images/download/bg.png" alt="" class="img">
   </van-overlay>
  </div>
</template>
<script>
import isweixin from "@/untils/isweixin.js"
import isPhone from "@/untils/isPhone.js"
import isAli from "@/untils/isAli.js"
export default {
  data() {
    return{
        hostUrl:window.location.host,   // 获取本地域名地址
        show:false,  // 遮罩层是否显示
    }
  },
  mounted(){
    if(isAli()){ 
      this.show = true
      return false
    }
    if(isweixin() && !isPhone()){
      this.show = true
      return false
    } 
    if(isPhone() && (this.hostUrl == "iot.yunruiot.com" || this.hostUrl == "iots.yunruiot.com")){
      window.location.href="https://a.app.qq.com/o/simple.jsp?pkgname=com.yunrui.gexingshangwang"
      return false
    }
    if(!isPhone() && !isweixin() && (this.hostUrl == "iot.yunruiot.com" || this.hostUrl == "iots.yunruiot.com")){
       window.location.href="https://imgwifi.yunruiot.com/android/ruiwifi_1_0.apk"
       return false
    }
  },
  methods:{
    getImageUrl() {
	  // 根据索引生成图像URL
      if(this.show && isPhone()){
        return require(`@/assets/images/index/downLoad.jpg`);
      }
      
    },
  }
}
</script>
<style lang="less" scoped>
.download{
  // background: url('../../assets/images/index/downLoad.jpg') no-repeat;
  background-size: 100%;
  background-repeat: no-repeat;
  width: 100%;
  min-height: 100vh;
  .img{
    padding-top: 40px;
    margin: 0 auto;
    display: block;
    width: 90%;
  }
}
</style>