/*
 * @Author: error: git config user.name && git config user.email & please set dead value or install git
 * @Date: 2022-06-15 17:30:41
 * @LastEditors: error: git config user.name && git config user.email & please set dead value or install git
 * @LastEditTime: 2022-06-15 17:31:46
 * @FilePath: \newwifisvn\src\untils\isAli.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
export const isAli = function () {
  var ua = window.navigator.userAgent.toLowerCase();
  // var app = navigator.appVersion;
  // var isXiaomi = u.indexOf('XiaoMi') > -1; // 小米手机
  if (ua.match(/AlipayClient/i) == "alipayclient") {
      return true
  }else{
      return false
  }
}
export default isAli